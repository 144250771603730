.aboutevent-container-image img {
  /* width: 100%; */
  position: relative;
  max-height: 300px;
  max-width: auto;
  margin: auto;
  border-radius: 24px;
}

.aboutevent-container-image a {
  cursor: pointer;
}

.aboutevent-container-contents {
  max-width: 80%;
  margin: -50px auto 50px auto;
  font-family: Inter;
  line-height: 34px;
  text-align: center;
  background-color: rgba(255, 248, 244, 1);
  padding: 20px;
  border-radius: 28px;
  /* background-color: red; */
}

.aboutevent-slider-container {
  max-width: 80%;
  margin: auto;
  /* background-color: red; */
  display: grid;
  grid-template-columns: 34.6% 66%;
  /* gap: 13px; */
}

.aboutevent-slider-container .slider-container img {
  border: 3px solid white;
}

.aboutevent-container-contents h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

.aboutevent-container-Abeventpillars {
  /* background-color: rgba(255, 248, 244, 0.3); */
}

.aboutevent-container-external-resources {
  background-color: rgba(255, 248, 244, 0.3);
}

.image-lebel {
  position: absolute;
  right: 0;
  padding: 20px;
  font-family: Inter;
  font-weight: 600;
}

@media (max-width: 568px) {
  .aboutevent-container-contents {
    max-width: 100%;
    margin-top: 10px;
  }
  .aboutevent-slider-container {
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .aboutevent-slider-container .static-slider {
    max-width: 80%;
    margin: auto;
  }

  .aboutevent-container-image img {
    width: 100%;
    position: relative;
    height: auto;
    margin: auto;
    border-radius: 12px;
  }

  .image-lebel {
    position: absolute;
    right: 0;
    padding: 20px;
    font-family: Inter;
    font-weight: 600;
  }
}
