.footer-rbottom {
  display: flex;
  justify-content: space-around;
}

.footer-rbottom p {
  font-family: Jua;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}

.footer-rbottom a {
  text-decoration: none;
  font-family: Jua;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 15px ;
  text-align: center;
  color: black !important;
}

.footer-rbottom div span {
  background: rgba(255, 115, 21, 1);
  padding: 5px;
  border-radius: 50%;
  margin-left: 10px;
}

@media (max-width: 568px) {
  .footer-rbottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .footer-rbottom div {
    margin: auto;
  }
}
