.ourPillars-container {
  margin: 5rem 0 5rem 0;
}

.ourPillars-title {
  text-align: center;
  margin: 0 0 2rem 0;
}

.ourPillars-contents {
  max-width: 1360px;
  /* background-color: red; */
  margin: auto;
}

.slider-container {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 20px;
  margin: 0;
}

/* .custom-slide {
  width: auto !important;
  height: 234px;
} */

@media (max-width: 968px) {
  .ourPillars-contents {
    max-width: 90%;
    /* float: right; */
  }

  .custom-slide {
    width: auto !important;
    height: auto !important;
  }

  .ourPillars-title {
    padding-top: 70px;
    /* background-color: red; */
  }

  .slider-container {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 20px;
    margin: 0;
  }
}

@media (max-width: 568px) {
  .ourPillars-title {
    padding-top: 0px;
  }

  .ourPillars-container {
    margin: 2rem 0;
  }
}
