.where-we-work-container {
  background: url("../../assets/Cameroon\ Map.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 50px;
}

.where-title-container {
  text-align: center;
  margin: 50px;
}

.where-we-work-text {
  max-width: 85%;
  background-color: rgba(255, 248, 244, 1);

  margin: auto;
  border-radius: 28px;
  padding: 30px 20px;
  text-align: start;
  font-family: Inter;
  font-size: 20px;
}

.where-we-work-text a {
  color: orange;
  text-decoration: none;
}

.where-we-work-text span {
  font-weight: bold;
}

.where-we-work-text p {
  margin: 20px 0px;
}

.where-we-work-text {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 30px;
}

.where-we-work-text img {
  width: 40vw;
  border-radius: 24px;
}

@media (max-width: 968px) {
  .where-we-work-text {
    display: flex;
    justify-content: space-between;
    /* gap: 30px; */
    flex-direction: column-reverse;
  }

  .where-we-work-text img {
    width: 85vw;

  }
}

@media (max-width: 568px) {
  .where-we-work-text {
    max-width: 95%;
    padding: 10px;
    text-align: center;
    font-size: 18px;
  }

  .where-we-work-text {
    display: flex;
    justify-content: space-between;
    /* gap: 30px; */
    flex-direction: column-reverse;
  }

  .where-we-work-text img {
    width: 90vw;
  }
}