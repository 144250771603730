.inpartners-container {
  max-width: 1108px;
  height: 392px;
  margin: 0px auto 20px auto;
  border-radius: 22px;
  background: linear-gradient(90deg, #2e2321 9.3%, rgba(46, 35, 33, 0.1) 100%),
    url("../../assets/cynthiaF.jpg") no-repeat;
  background-position: right;
}

.inpartners-inner-container .small {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: rgba(255, 115, 21, 1);
}

.inpartners-inner-container .lg {
  font-family: Jua;
  font-size: 40px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: rgba(243, 243, 243, 1);
  max-width: 475px;
}

.inpartners-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 30px;
  height: 100%;
  padding-left: 110px;
  position: relative;
}

.cynthia-label {
  position: absolute;
  right: 295px;
  bottom: 30px;
  color: rgb(245, 228, 228);
}

.cynthia-label p {
  font-family: Inter;
}

@media (max-width: 968px) {
  .inpartners-container {
    max-width: 660px;
  }
}

@media (max-width: 568px) {
  .inpartners-inner-container .lg {
    font-size: 24px;
  }

  .inpartners-container {
    max-width: 650px;
    padding-left: 20px;
    padding-right: 20px;
  }

  /* 
  .inpartners-container {
    background: linear-gradient(90deg, #2E2321 9.3%, rgba(46, 35, 33, 0.1) 100%),
      url("../../assets/partner.png") no-repeat;
  } */
  .ipartner-container .inpartners-container {
    background: linear-gradient(90deg, #2e2321 9.3%, rgba(46, 35, 33, 0.1) 100%),
      url("../../assets/cynthiaF.jpg") no-repeat;
    background-position: center;
    right: 40px;
    max-width: 100%;
  }

  .cynthia-label {
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: rgb(255, 255, 255);
    font-size: 12px;
  }
}