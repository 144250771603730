@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jua&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
    
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
button {
  font-family: Jua, "sans-serif";
  font-weight: 200;
}

p {
  font-family: Inter, "sans-serif";
}

h3 {
  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  /* font-weight: 700; */
  font-style: normal;
}
