.form-container {
  width: 500px;
}

.input-form-g1 input {
  border-radius: 28px;
  padding: 20px;
  background: rgba(255, 229, 212, 1);
  outline: none;
  color: rgba(255, 115, 21, 0.5);
  border: none;
  width: 48%;
}

.input-form-g1 input:last-child {
  margin-left: 16px;
}

.input-form-g1 input::placeholder {
  color: rgba(255, 115, 21, 0.5);
}

.form-container textarea {
  width: 100%;
  height: 180px;
  border-radius: 28px;
  background: rgba(255, 229, 212, 1);
  outline: none;
  border: none;
  margin-top: 15px;
  padding: 20px;
  color: rgba(255, 115, 21, 0.5);
}

.form-container textarea::placeholder {
  /* padding: 20px; */
  color: rgba(255, 115, 21, 0.5);
}

.form-container .button {
  width: 100%;
  border-radius: 22px;
  padding: 20px;
  background: rgba(255, 115, 21, 1);
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  margin-top: 5px;
}

.form-container .button:hover {
  background: rgba(255, 115, 21, 0.8);
}

@media (max-width: 968px) {
  .form-container {
    padding: 20px;
    /* background-color: red; */
    margin: auto;
    width: 90%;
  }
}

@media (max-width: 568px) {
  .form-container {
    max-width: 100%;
    padding: 10px;
  }

  .input-form-g1 input:last-child {
    margin-left: 10px;
  }
}
