.partner-card {
  width: 183px;
  height: 183px;
  background-color: rgba(255, 199, 161, 1);
}

/* @media (max-width: 968px) {
  .partner-card {
    width: 170px;
    height: 170px;
    background-color: red;
  } */

