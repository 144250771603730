.who-we-are-container {
  background: url("../../assets/Cameroon\ Map.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 50px;
}

.who-we-are-contents {
  max-width: 1138px;
  /* background-color: red; */
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* position: relative; */
}

.who-we-are-container .title-container {
  text-align: center;
  margin-bottom: 80px;
}

.who-we-are-left {
  position: relative;
}

.who-we-are-left img:nth-child(2) {
  position: absolute;
  bottom: 0;
  right: -150px;
  bottom: -80px;
  width: 216px;
  height: 216px;
  border: 4px solid #fff;

  border-radius: 50%;
}

.who-we-are-left img:last-child {
  position: absolute;
  width: 216px;
  height: 215px;
  border-radius: 50%;
  left: -120px;
  border: 4px solid #fff;
}

.who-we-are-right p {
  color: rgba(150, 150, 150, 1);
  font-size: 17px;
  line-height: 24px;
}

.who-we-are-left img:first-child {
  width: 373px;
  height: 373px;
  border-radius: 50%;
}

.who-we-are-right:last-child {
  margin-top: 10px;
}

.who-we-are-right-contents a {
  text-decoration: none;
  color: black;
}

.who-we-are-right {
  min-width: 300px;
}

.who-we-are-right .pillar-card h3 {
  font-size: 24px;
  font-weight: 400;
  font-family: "EB Garamond";
  /* padding: 20px 30px;   */
  cursor: pointer;
}

.whobutton {
  margin-top: 15px;
}

@media (max-width: 968px) {
  .who-we-are-container {
    /* background-color: red; */
    margin-top: 15rem;
    min-width: 100%;
    position: relative;
    margin-bottom: 35px;
  }

  .who-we-are-contents {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 70px;
    align-items: center;
    /* background-color: blue; */
    min-width: 100%;
  }

  .who-we-are-container .title-container {
    margin-bottom: 0px;
    margin-top: 90px;
  }

  .who-we-are-right-contents h3 {
    font-size: 30px;
  }

  .who-we-are-right {
    text-align: start;
  }

  .who-we-are-right p {
    font-size: 30px;
    line-height: 34px;
    margin: 50px 0;
    text-align: center;
  }

  .who-we-are-contents .title-container {
    text-align: center;
    width: fit-content;
    /* margin-left: 55px; */
    margin-bottom: 0;
  }
}

@media (max-width: 568px) {
  .who-we-are-container {
    max-width: 90%;
    margin: 0rem auto 10px auto;
    /* background-color: red; */
  }

  .who-we-are-contents {
    min-width: 90%;
    padding: 20px;
  }

  .who-we-are-right .title-container {
    width: 95%;
    margin: auto;
  }

  .who-we-are-right .title-container h2 {
    font-size: 34px;
    text-align: center;
  }

  .who-we-are-right p {
    font-size: 14px;
    line-height: 17px;
    margin: 10px 0;
  }

  .who-we-are-left img:first-child {
    width: 273px;
    height: 273px;
  }

  .who-we-are-left img:nth-child(2) {
    width: 130px;
    height: 130px;
    right: -40px;
    bottom: -35px;
  }

  .who-we-are-left img:last-child {
    width: 130px;
    height: 130px;
    left: -40px;
    border-radius: 50%;
  }

  .who-we-are-right-contents h3 {
    padding: 10px 50px;
  }
}
