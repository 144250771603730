.footer-container {
  background: linear-gradient(180deg, #ffffff 0%, #fff8f4 39.23%, #fff8f4 100%);
}

.footer-container hr {
  width: 1172px;
  height: 0px;
  margin: 50px auto;
  border: 1px solid var(--Border-Neutral-tertiary, rgba(178, 178, 178, 1));
  /* opacity: 0px; */
}

.footer-contents {
  max-width: 1076px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.footer-contents-b1 {
  width: 292px;
  height: 178px;
  gap: 17px;
  /* opacity: 1px; */
  display: flex;
  flex-direction: column;
}

.footer-contents-b1 .h3-container {
  font-size: 10px;
  background-color: rgba(255, 248, 244, 1);
  /* line-height: 12px; */
  border-radius: 38px;
  padding: 15px 0px;
  text-align: center;
}

.footer-contents-b1 .h3-container .dot {
  height: 16px;
  width: 16px;
}

.footer-contents-b1 p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: rgba(150, 150, 150, 1);
}

.footer-contents-b2 h3,
.footer-contents-b3 h3,
.footer-contents-b4 h3,
.footer-contents-b5 h3 {
  margin-bottom: 30px;
}

.footer-contents-b5 h3 {
  margin-bottom: 10px !important;
}

.footer-contents-b2 a,
.footer-contents-b3 a,
.footer-contents-b4 a {
  display: block;
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -0.08em;
  text-align: left;
  margin-top: -40px;
}

.footer-connect a {
  display: flex;
  align-items: center;
  font-family: Inter;
  text-decoration: none;
  color: black;
}

.footer-contents .dot {
  top: 4px;
}

@media (max-width: 968px) {
  .footer-container {
    max-width: 95%;
    margin: auto;
  }

  .footer-contents {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    row-gap: 50px;
  }

  .footer-contents-b1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .footer-contents-b5 {
    grid-column-start: end;
    margin-bottom: -15px;
  }

  .footer-container hr {
    max-width: 95%;
  }
}

@media (max-width: 568px) {
  .footer-contents {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-contents-b5 {
    margin-bottom: -14px;
    grid-column-start: 2;
    grid-column-end: 3;

  }

  /* .footer-contents-b1 {
    grid-column-start: 1;
    grid-column-end: 3;
    +
  } */

  .footer-contents-b1 p {
    text-align: center;
  }

  .footer-contents .dot {
    top: 4px;
  }

  .footer-button {
    text-align: center;
  }

  .footer-connect span {
    font-size: 15px;
  }
}