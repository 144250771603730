.partner-us-contents {
  max-width: 1087px;
  margin: auto;
  background: rgba(255, 251, 249, 1);
  border-radius: 22px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.partner-us-contents-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.partner-us-contents-left p {
  margin-top: 20px;
}

.partner-us-contents-left p a {
  color: rgba(255, 115, 21, 1);
  text-decoration: none;
}

@media (max-width: 968px) {
  .partner-us-contents {
    flex-direction: column;
  }

  .partner-us-contents-left {
    width: 90%;
    margin: auto;
    padding: 20px;
  }

  .partner-us-contents-left .card-social {
    text-align: center;
    margin: auto;
    max-width: fit-content;
  }
}

@media (max-width: 568px) {
  .partner-us-contents-left {
    padding: 10px 30px;
    text-align: center;
  }
}
