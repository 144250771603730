.swipper-card {
  background: rgba(255, 251, 249, 1);
  max-width: 374px;
  /* padding: 10px; */
  margin-bottom: 50px;
  height: 560px;
  border-radius: 0 0 20px 20px;
}

.swipper-card .s-img img {
  max-width: 374px;
  height: 249px;
}

.swipper-card h3 {
  margin: 20px 0;
  padding: 20px;
  font-size: 16px;
}

.swipper-card p {
  margin: 20px 0;
  padding: 0px 20px 20px 20px;
  color: rgba(150, 150, 150, 1);
}

.swipper-button {
  padding: 0px 20px 20px 20px;
}

@media (max-screen: 968px) {
  /* .swipper-card-container {
    padding: 0 120px;
  } */
  /* .swipper-card {
    max-width: 300px;
  }

  .swipper-card .s-img img {
    max-width: 100px;
    height: 249px;
  } */
}
