.newsletter-contents {
  max-width: 1202px;
  margin: 100px auto 20px auto;
  /* background-color: red; */
  text-align: center;
}

.newsletter-contents-left h3 {
  font-family: Jua;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

/* .newsletter-contents-left p {
  color: rgba(150, 150, 150, 1);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
} */

.newsletter-contents-right {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

/* .right-button {
  display: flex;
  flex-direction: row;
  position: relative;
} */

/* .right-button button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 15px;
  height: 45px;
} */

/* .right-button input {
  outline: none;
  border: none;
  background: rgba(255, 224, 204, 1);
  border-radius: 20px;
  width: 450px;
  height: 45px;
  font-family: Jua;
  padding-left: 12px;
} */

.content-right-email {
  display: flex;
  flex-direction: flex-row;
  justify-content: center;
  align-items: center;
  color: rgba(150, 150, 150, 1);
  font-size: 17px;
  margin-right: 5px;
  font-family: Inter;
  margin-bottom: 20px;
}

.content-right-email a {
  text-decoration: none;
  margin: 20px 10px;
  color: rgba(150, 150, 150, 1);
  font-size: 17px;
}

.content-right-email img {
  width: 12%;
  margin-right: 3px;
  /* background-color: red; */
}

.newsletter-info {
  display: inline-flex;
  justify-content: space-between;
  gap: 50px;
}

@media (max-width: 968px) {
  .newsletter-container {
    /* background-color: red; */
    max-width: 90%;
    margin: auto;
  }

  .newsletter-contents-right {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  /* .newsletter-contents-left h3 {
    text-align: start;
  } */

  .newsletter-contents-right {
    display: block;
  }
}

@media (max-width: 568px) {
  .newsletter-contents-right {
    text-align: start;
  }

  .content-right-email {
    justify-content: start;
  }

  .right-button input {
    width: 300px;
  }

  .newsletter-info {
    display: block;
  }

  .right-contact p {
    margin-left: 20px;
  }

  .right-location p {
    margin-left: 14px;
  }

  .phone-Icon {
    padding-left: 5px;
  }

  .content-right-email img {
    width: 10%;
    margin-right: 3px;
    /* background-color: red; */
  }
}
