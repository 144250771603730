.navigation-container {
  background: url("../../assets/topbgImg.png");
  background-size: 100%;
  height: 370px;
  max-width: 100%;
  background-repeat: no-repeat;
}

.navtigation-top-contents {
  padding-top: 180px;
  position: relative;
  text-align: center;
}

.navtigation-top-contents h3 {
  background-color: rgba(255, 248, 244, 1);
  margin: auto;
  width: fit-content;
  padding: 21px 48px 21px 48px;
  border-radius: 70px;
  font-size: 92px;
  font-weight: 500;
  letter-spacing: -6px;
  line-height: 93px;
}

.nav-top-header .dot {
  width: 60px;
  height: 60px;
}

.dot {
  background: rgba(255, 115, 21, 1);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: inline-block;
  border: 1px solid rgba(255, 115, 21, 1);
  position: relative;
  top: -2px;
}

.h3-container a {
  text-decoration: none;
  color: black;
}

.sticky {
  position: fixed;
  height: 185px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  top: 0px;
  z-index: 100;
}

.sticky h3 {
  margin-top: 20px;
}

.main-content {
  padding-top: 100px; /* Adjust this value based on the height of your sticky header */
}

@media (max-width: 968px) {
  .main-content{
    padding-top: 50px;
  }
  .navtigation-top-contents h3 {
    font-size: 44px;
    line-height: 34px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0px;
  }

  .navtigation-top-contents h2 {
    font-size: 56px;
    line-height: 64px;
    max-width: 656px;
  }

  .nav-top-header .dot {
    width: 25px;
    height: 25px;
  }

  .navtigation-top-contents p {
    font-size: 19px;
    max-width: 564px;
    margin-top: 30px;
  }
}

@media (max-width: 568px) {
  .navigation-container {
    height: 200px;
  }

  .navtigation-top-contents {
    padding-top: 50px;
  }

  .navtigation-top-contents a h3 {
    font-size: 28px;
    line-height: 34px;
    padding: 21px 10px 21px 10px;
    letter-spacing: -1px;
  }

  .navtigation-top-contents h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .navtigation-top-contents p {
    font-size: 14px;
    line-height: 17px;
    padding: 0 45px;
  }

  .h3-container {
    height: 85px;
    padding: 30px 0;
  }

  .dot {
    width: 17px;
    height: 17px;
    top: 3px;
  }

  .sticky {
    height: 155px;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .sticky h3 {
    margin-top: 5px;
  }
}