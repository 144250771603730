.oursupport-container {
  background: url("../../assets/Cameroon\ Map.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: right;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}

.custom-slide {
  /* margin-right: 30px; */
}

.oursupport-title {
  text-align: center;
  margin: 130px 0px 60px 0px;
}

.swipper-contents {
  margin-left: 150px;
  position: relative;
}

.swiper-pagination-bullet-active {
  background: rgba(255, 115, 21, 1) !important;
  /* Active bullet color */
  transform: scale(1.2);
  /* Slightly enlarge active bullet */
}

.ipartner-container {
  margin-top: 50px;
}

.custom-slide {
  border-radius: 50%;
}

@media (max-width: 968px) {
  .oursupport-container {
    /* background-color: pink; */
    max-width: 90%;
    margin: auto;
  }

  .ipartner-container {
    max-width: 100%;
    margin: auto;
  }

  .swipper-contents {
    margin-left: 0;
  }

  .oursupport-title {
    margin: 10px 0 30px 0;
  }
}

@media (max-width: 568px) {
  .ipartner-container .inpartners-container {
    max-width: 279px;
    margin: auto;
    text-align: center;
  }

  .ipartner-container .inpartners-inner-container {
    padding: 0;
    align-items: center;
  }

  .inpartners-inner-container .lg {
    text-align: center !important;
  }
}
