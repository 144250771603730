.fill {
  border: none;
  outline: none;
  color: #fff;
  border: 2px solid rgba(32, 32, 32, 1);
  padding: 10px 30px;
  background-color: rgba(32, 32, 32, 1);
  gap: 0px;
  border-radius: 22px;
  cursor: pointer;
  font-size: 16px;
}

.empty {
  padding: 10px 30px;
  border: 2px solid rgba(32, 32, 32, 1);
  border-radius: 22px;
  background-color: transparent;
  cursor: pointer;
}

.fill-orange {
  border: none;
  outline: none;
  color: #fff;
  border: 2px solid rgba(255, 115, 21, 1);
  padding: 10px 30px;
  background-color: rgba(255, 115, 21, 1);
  gap: 0px;
  border-radius: 22px;
  cursor: pointer;
  font-size: 16px;
}

.fill-orange:hover {
  background-color: transparent;
  transition: 1s;
  color: rgba(255, 115, 21, 1);
}

.fill:hover {
  border: 2px solid rgba(32, 32, 32, 1);
  background-color: transparent;
  color: rgba(32, 32, 32, 1);
  transition: 1s;
}

.empty:hover {
  background: rgba(32, 32, 32, 1);
  color: #fff;
  transition: 1s;
}
